import 'echarts/lib/chart/line';
import 'echarts/lib/component/axis';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import Vue from 'vue';
import ECharts from 'vue-echarts';
import { Component, Prop } from 'vue-property-decorator';
import { SolarRadiationDefaultChartSettings, xAxisDateFormatterGenerator, tooltipFormatterGenerator } from "./chartSettings";
import { UnitsMeasure } from '@/@types/dto/fiware/enums/unitMeasures';

@Component({ components: { chart: ECharts } })
export default class SolarRadiationChart extends Vue {

  @Prop()
  radiations: { time: Date, avg: number, min?: number, max?: number }[];

  get chartOptions() {
    console.debug("this.radiations", this.radiations)
    if (!this.radiations) return null;
    let co = Object.assign({}, SolarRadiationDefaultChartSettings);
    co.dataset.source = this.radiations;
    co.xAxis.axisLabel.formatter = xAxisDateFormatterGenerator(this.radiations);
    co.tooltip.formatter = tooltipFormatterGenerator(this.radiations, UnitsMeasure['solarRadiation']);
    return co;
  }
}
