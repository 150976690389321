import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/custom';
import { UnitsMeasure } from '@/@types/dto/fiware/enums/unitMeasures';
import { xAxisDateFormatterGenerator, tooltipFormatterGenerator, xAxiCommonConfing, yAxisCommonConfig } from './chartSettings';

@Component({ components: { chart: ECharts } })
export default class WindgaugeChart extends Vue {

  @Prop()
  winds: { time: Date, direction: number, speed: number }[];

  get chartOptions() {
    console.debug("this.winds", this.winds)
    if (!this.winds) return null;
    let co = {
      title: { text: 'Winds', textStyle: { color: '#4D4F5C' } },
      tooltip: { trigger: 'axis', formatter: null },
      xAxis: xAxiCommonConfing,
      yAxis: Object.assign({ name: UnitsMeasure['windSpeed'] }, yAxisCommonConfig),
      dataset: {
        source: this.winds,
        dimensions: ['time', 'direction', 'speed'],
      },
      series: [{
        name: 'Wind speed',
        type: 'line',
        dimensions: ['time', 'speed']
      }, {
        name: 'Wind direction',
        type: "custom",
        dimensions: ['time', 'direction', 'speed'],
        encode: {
          x: 0,
          y: 2
        },
        renderItem: (param, api) => {
          const point = api.coord([
            api.value('time'),
            api.value('speed')
          ]);
          return {
            type: 'path',
            position: point,
            rotation: (90 - api.value('direction')) * Math.PI / 180,
            shape: {
              pathData: 'M31 16l-15-15v9h-26v12h26v9z',
              x: -18 / 2,
              y: -18 / 2,
              width: 18,
              height: 18
            },
          }
        }
      }]
    };
    co.xAxis.axisLabel.formatter = xAxisDateFormatterGenerator(this.winds);
    co.tooltip.formatter = tooltipFormatterGenerator(this.winds, { speed: UnitsMeasure['windSpeed'], direction: UnitsMeasure['windDirection'] });
    return co;
  }
}